import { actionTypes } from "../../constants/actionTypes"
import axios from "axios"

export const getAllFavouritesRequest = () => {
    return {
        type: actionTypes.GET_ALL_FAVOURITES_REQUEST,
    }
}

export const getAllFavouritesSuccess = (favourites) => {
    return {
        type: actionTypes.GET_ALL_FAVOURITES_SUCCESS,
        payload: favourites
    }
}


export const saveFavouriteSuccess = () => {
    return {
        type: actionTypes.SAVE_FAVOURITE_SUCCESS,
    }
}

export const likeFavouriteSuccess = () => {
    return {
        type: actionTypes.LIKE_FAVOURITE_SUCCESS,
    }
}

export const getAllFavouritesFailure = (error) => {
    return {
        type: actionTypes.GET_ALL_FAVOURITES_FAILURE,
        payload: error
    }
}


//get all Favourites
export const getAllFavourites = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllFavouritesRequest());

        var config = {
            method: 'post',
            url: 'https://cms-backend.myidentitypass.com/resource/favourite/list',
            headers: { },
            data:data
        };

        axios(config).then(function (response) {
            dispatch(getAllFavouritesSuccess(response.data))
            resolve(response.data)
            // console.log(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllFavouritesFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}

//save Favourite
export const saveFavourite = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllFavouritesRequest());

        var config = {
            method: 'post',
            url: 'https://cms-backend.myidentitypass.com/resource/favourite/save',
            headers: {},
            withCredentials: true,
            data : data,
        };


        axios(config).then(function (response) {
            dispatch(saveFavouriteSuccess(response.data))
            resolve(response.data)
            console.log(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllFavouritesFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}


//like Favourite
export const likeFavourite = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllFavouritesRequest());

        var config = {
            method: 'post',
            url: 'https://cms-backend.myidentitypass.com/resource/article/like',
            headers: {},
            data : data,
        };
        axios(config).then(function (response) {
            dispatch(likeFavouriteSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllFavouritesFailure(error_message))
            reject(error)
            console.log(error);
        })
        .finally(function () {
            // always executed
        });;
    })
}

