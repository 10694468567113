import { actionTypes } from "../../constants/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
}

export const sendPublicationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.SEND_PUBLICATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
            case actionTypes.SEND_PUBLICATION_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                };
        case actionTypes.SEND_PUBLICATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        default:
            return state;
    }
}

