import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OurBlog from './Component/OurBlog';
import Home from "./Component/Home";
import SaveBlog from "./Component/SaveBlog";
import FullArticle from "./Component/FullArticle";
import OurPublication from "./Component/OurPublication";
import ExploreTags from './Component/exploreTags';
import ScrollToTop from './Component/scrollToTop';
import "../src/asset/all.css";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ourBlog" element={<OurBlog />} />
          <Route path="/saveBlog" element={<SaveBlog />} />
          <Route path="/fullArticle/:ref" element={<FullArticle />} />
          <Route path="/publication" element={<OurPublication />} />
          <Route path="/ExploreTags/:tag" element={<ExploreTags />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
