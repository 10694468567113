import { actionTypes } from "../../constants/actionTypes";

const initialState = {
    isLoading:false,
    error:null,
    blog:[],
}

export const cmsReducer = (state = initialState, {type, payload }) =>{
    switch (type) {
        case actionTypes.GET_ALL_BLOG:
            return state;
        default:
            return state;
    }
}