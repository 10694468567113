import React from "react";
import "../asset/footer.css";
import { Link } from "react-router-dom";
import { MdOutlineBookmarkAdd} from 'react-icons/md';
import { RiHomeLine } from "react-icons/ri";

const Footer = () => {
  return (
    // <div
    //   style={{
    //     background: "#fff",
    //     borderBottom: "1px solid #C1C7D0",
    //     boxShadow: "none",
    //     width: "100%",
    //     height: "50px",
    //   }}
    // >
      <div className="footerIcons">
        {/* <ul className="icon-ui"> */}
          {/* <li> */}
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              <RiHomeLine className="homeIcon"/>
              {/* <span className="iconText">Home</span> */}
              <p className="">Home</p>
            </Link>
          {/* </li> */}
          {/* <li> */}
            <Link
              to="/saveBlog"
              style={{ textDecoration: "none", color: "black" }}
            >
              <MdOutlineBookmarkAdd
                className="iconlist"
                style={{ color: "black" }}
              />
              <p className="">Saved</p>
            </Link>
          {/* </li> */}
        {/* </ul> */}
      </div>
    // </div>
  );
};

export default Footer;
