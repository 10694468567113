import { cmsReducer } from "./CMS";
import { blogReducer } from "./blog";
import { publicationReducer } from "./publication";
import { sendPublicationReducer } from "./sendPublication";
import { resourcesReducer } from "./resources";
import { tagsReducer } from "./tags";
import { favouritesReducer } from "./favourites";

export const reducers = {
    cmsReducer,
    blogReducer,
    publicationReducer,
    sendPublicationReducer,
    resourcesReducer,
    tagsReducer,
    favouritesReducer,
}