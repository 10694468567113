import React from 'react'
import ContentLoader, { Code, List } from 'react-content-loader'

export default function Mainloader() {
  return (
    <>
    <Code />
    <Code  height={"160"} />
    <List height={"180"} />
    <List height={"180"} />
    <Code  height={"160"} />
    </>
  )
}
