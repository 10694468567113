import { actionTypes } from "../../constants/actionTypes"
import axios from "axios"

export const getAllResourcesRequest = () => {
    return {
        type: actionTypes.GET_ALL_RESOURCES_REQUEST,
    }
}

export const getAllResourcesSuccess = (resources) => {
    return {
        type: actionTypes.GET_ALL_RESOURCES_SUCCESS,
        payload: resources
    }
}

export const getAllResourcesFailure = (error) => {
    return {
        type: actionTypes.GET_ALL_RESOURCES_FAILURE,
        payload: error
    }
}

//get all Resources
export const getAllResources = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllResourcesRequest());

        var config = {
            method: 'get',
            url: 'https://cms-backend.myidentitypass.com/resource/collections',
            headers: {}
        };


        axios(config).then(function (response) {
            dispatch(getAllResourcesSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllResourcesFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}

