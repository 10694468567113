export const actionTypes = {
    GET_ALL_RESOURCES_REQUEST: "GET_ALL_RESOURCES_REQUEST",
    GET_ALL_RESOURCES_SUCCESS : "GET_ALL_RESOURCES_SUCCESS",
    GET_ALL_RESOURCES_FAILURE: "GET_ALL_RESOURCES_FAILURE",


    GET_ALL_BLOG_REQUEST : "GET_ALL_BLOG_REQUEST",
    GET_ALL_BLOG_SUCCESS : "GET_ALL_BLOG_SUCCESS",
    GET_SINGLE_BLOG_SUCCESS : "GET_SINGLE_BLOG_SUCCESS",
    GET_ALL_BLOG_FAILURE: "GET_ALL_BLOG_FAILURE",


    GET_ALL_PUBLICATION_REQUEST : "GET_ALL_PUBLICATION_REQUEST",
    GET_ALL_PUBLICATION_SUCCESS : "GET_ALL_PUBLICATION_SUCCESS",
    GET_ALL_PUBLICATION_FAILURE: "GET_ALL_PUBLICATION_FAILURE",


    SEND_PUBLICATION_REQUEST : "SEND_PUBLICATION_REQUEST",
    SEND_PUBLICATION_SUCCESS : "SEND_PUBLICATION_SUCCESS",
    SEND_PUBLICATION_FAILURE: "SEND_PUBLICATION_FAILURE",


    GET_ALL_TAGS_REQUEST : "GET_ALL_TAGS_REQUEST",
    GET_ALL_TAGS_SUCCESS : "GET_ALL_TAGS_SUCCESS",
    GET_ALL_TAGS_FAILURE: "GET_ALL_TAGS_FAILURE",


    GET_ALL_FAVOURITES_REQUEST : "GET_ALL_FAVOURITES_REQUEST",
    GET_ALL_FAVOURITES_SUCCESS : "GET_ALL_FAVOURITES_SUCCESS",
    SAVE_FAVOURITE_SUCCESS : "SAVE_FAVOURITE_SUCCESS",
    LIKE_FAVOURITE_SUCCESS : "LIKE_FAVOURITE_SUCCESS",
    GET_ALL_FAVOURITES_FAILURE: "GET_ALL_FAVOURITES_FAILURE",

}