import { actionTypes } from "../../constants/actionTypes"
import axios from "axios"

export const getAllBlogRequest = () => {
    return {
        type: actionTypes.GET_ALL_BLOG_REQUEST,
    }
}

export const getAllBlogSuccess = (blog) => {
    return {
        type: actionTypes.GET_ALL_BLOG_SUCCESS,
        payload: blog
    }
}

export const getSingleBlogSuccess = (data) => {
    return {
        type: actionTypes.GET_SINGLE_BLOG_SUCCESS,
        payload: data
    }
}

export const getAllBlogFailure = (error) => {
    return {
        type: actionTypes.GET_ALL_BLOG_FAILURE,
        payload: error
    }
}

//get all Blogs
export const getAllBlog = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllBlogRequest());

        var config = {
            method: 'get',
            url: 'https://cms-backend.myidentitypass.com/resource/blog/collections',
            headers: {}
        };


        axios(config).then(function (response) {
            dispatch(getAllBlogSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllBlogFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}


//get all Blogs
export const getSingleBlog = (ref) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllBlogRequest());

        var config = {
            method: 'get',
            url: 'https://cms-backend.myidentitypass.com/resource/read/' + ref,
            headers: {}
        };

        axios(config).then(function (response) {
            dispatch(getSingleBlogSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllBlogFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}
