import { actionTypes } from "../../constants/actionTypes"
import axios from "axios"

export const getAllTagsRequest = () => {
    return {
        type: actionTypes.GET_ALL_TAGS_REQUEST,
    }
}

export const getAllTagsSuccess = (tags) => {
    return {
        type: actionTypes.GET_ALL_TAGS_SUCCESS,
        payload: tags
    }
}

export const getAllTagsFailure = (error) => {
    return {
        type: actionTypes.GET_ALL_TAGS_FAILURE,
        payload: error
    }
}

//get all tags
export const getAllTags = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllTagsRequest());

        var config = {
            method: 'get',
            url: 'https://cms-backend.myidentitypass.com/resource/tag/list',
            headers: {}
        };


        axios(config).then(function (response) {
            dispatch(getAllTagsSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllTagsFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}

