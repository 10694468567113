import { actionTypes } from "../../constants/actionTypes"
import axios from "axios"

export const sendPublicationRequest = () => {
    return {
        type: actionTypes.SEND_PUBLICATION_REQUEST,
    }
}
export const sendPublicationSuccess = () => {
    return {
        type: actionTypes.SEND_PUBLICATION_SUCCESS,
    }
}
export const sendPublicationFailure = (error) => {
    return {
        type: actionTypes.SEND_PUBLICATION_FAILURE,
        payload: error
    }
}


//send Publication
export const sendPublication = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(sendPublicationRequest());

        var config = {
            method: 'post',
            url: 'https://cms-backend.myidentitypass.com/resource/save/user/record',
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
        };


        axios(config).then(function (response) {
            dispatch(sendPublicationSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(sendPublicationFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}