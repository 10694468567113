import { actionTypes } from "../../constants/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    publication: [],
}

export const publicationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_ALL_PUBLICATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_ALL_PUBLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                publication:payload
            };
        case actionTypes.GET_ALL_PUBLICATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        default:
            return state;
    }
}

