import React, { useState } from "react";
import "../asset/publication.css";
import { Modal } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import emailImg from "../imageFolder/email.png";
import Modals from ".././Modals"
import { useDispatch, useSelector } from "react-redux";
import { sendPublication } from "../redux/actions/sendPublication";
import toast, { Toaster } from 'react-hot-toast';
import { BounceLoader } from "react-spinners";
import { Document, Page, pdfjs } from 'react-pdf';
import samplePDF from "../samplePDF.pdf"



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

const AllFraud = ({ item, key }) => {

	const [allModal, setAllModal] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [fullname, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [comment, setComment] = useState('');
	const [work_place, setWorkPlace] = useState('');
	// const [subscription, setSubscription] = useState(false);

	const [PDFFile, setPDFFile] = useState('')


	const [PDFVisibility, setPDFVisibility] = useState(false);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);


	const sendPublicationState = useSelector(state => state.sendPublicationReducer)
	const dispatch = useDispatch()

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = (e) => {
		setAllModal(false)
		setIsModalVisible(false)
	};

	// const handleModal = () => {
	//   setAllModal(true);
	// }

	let downloadPublication = (e) => {
		e.preventDefault()

		let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

		if (!fullname) {
			toast.error("please enter your Full name")
			return
		}
		else if (!email) {
			toast.error("email cannot be empty")
			return
		}
		else if (email_reg.test(email) === false) {
			toast.error("Email Address is invalid")
			return
		}
		else if (!work_place){
			toast.error("Work place cannot be empty")
			return
		}
		else {
			let data = {
				email,
				fullname,
				comment,
				publication_ref: item.reference,
				work_place
			}
			dispatch(sendPublication(data)).then(() => {
				setAllModal(true)
			}).catch(() => {
				toast.error(sendPublicationState.error)
			})

		}
	}

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}


	let removeTags = /<(.*?)>/g

	return (
		<>
			<Toaster />

			<Modal
				visible={PDFVisibility}
				onOk={()=>setPDFVisibility(false)}
				onCancel={()=>setPDFVisibility(false)}
				footer={
					<p>
						Page {pageNumber} of {numPages}
					</p>
				}
				className="createForm"
			>
				<div className="modalBody">
					<div>
						<Document file={PDFFile}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							<Page pageNumber={pageNumber} />
						</Document>
					</div>
				</div>

			</Modal>

			<div className="frauds">
				<div className="fraudImg">
					<img src={item?.publication?.image_banner} alt="download" />
				</div>
				<div className="fraudText">
					<h6 className="pub-heading">{item?.publication?.heading}</h6>
					<p className="pub-body">{item?.publication?.body.replace(removeTags, "")}</p>
					<div className="download-btn">
						<button className="fraudPdf-btn" onClick={() => {
							setPDFFile(item?.publication?.file)
							setPDFVisibility(true)
							}}
						>
								Review PDF
						</button>
						<button className="fraudDown-pdf" onClick={showModal}>
							Download PDF
						</button>
					</div>
				</div>
			</div>

			<div className="createformModal">
				<Modal
					visible={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={null}
					className="createForm"
				>
					{allModal === false ?
						<div className="modalBody">
							<div>
								<h5>Give us few details</h5>
								<p>
									We will like to send this to your email, kindly fill the form
								</p>
							</div>
							<div className="allForms">
								<div className="form-group">
									<input
										className="mb-3"
										type="name"
										placeholder="Enter full name"
										controlId="formBasicEmail"
										value={fullname}
										onChange={fullname => setFullName(fullname.target.value)}
									/>
								</div>
								<div className="form-group">
									<input
										className="mb-3"
										type="email"
										placeholder="Email address"
										controlId="formBasicEmail"
										value={email}
										onChange={email => setEmail(email.target.value)}
									/>
								</div>
								<div className="form-group">
									<input
										className="mb-3"
										type="name"
										placeholder="Work Place"
										controlId="formBasicEmail"
										value={work_place}
										onChange={workPlace => setWorkPlace(workPlace.target.value)}
									/>
								</div>
								<div className="form-group">
									<input
										className="mb-3"
										type="message"
										placeholder="Leave a comment (Optional)"
										controlId="formBasicEmail"
										value={comment}
										onChange={comment => setComment(comment.target.value)}
									/>
								</div>
							</div>
							{/* <div className="label-checkbox">
								<label class="switch" style={{ width: 56 }}>
									<input type="checkbox" checked={subscription} defaultChecked={subscription} onChange={subscribe => setSubscription(subscribe.target.checked)} />
									<span class="slider round"></span>
								</label>
								<p>Uncheck if you don’t want to receive future updates</p>
							</div> */}
							<div className="modalButton">
								<button className="bttn" onClick={downloadPublication}>
									<BounceLoader loading={sendPublicationState.isLoading} color={"white"} cssOverride={override} size={30} />
									{!sendPublicationState.isLoading ?
										"Download Reasources"
										:
										"Loading..."
									}
								</button>
							</div>
						</div>
						:
						<div className="thanksContainer">
							<div className="thanks">
								<div className="modalImg">
									<img src={emailImg} alt="email" />
								</div>
								<div>
									<h5>Sent Successfully</h5>
									<p>
										We just sent you this resource to the email you provided, thank you for downloading, enjoy and share this knowledge.
									</p>
								</div>
								<div className="thanks" style={{ alignItems: "center !important" }}>
									<button className="bttn" onClick={handleCancel}>Done, Thank you</button>
								</div>
							</div>
						</div>
					}
					<div />
				</Modal>
			</div>
		</>
	);
};

export default AllFraud;
