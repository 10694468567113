import React, { useState, useEffect } from "react";
import "../asset/publication.css";
import { Modal } from "antd";
import "antd/dist/antd.css";
import { Link, useLocation } from "react-router-dom";
import emailImg from "../imageFolder/email.png";
// import Modals from "../Modals"
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { getAllResources } from "../redux/actions/resources";
import Sidebar from "./Sidebar";
import { WiMoonFull } from "react-icons/wi";
import Footer from "./Footer";
import { BounceLoader } from "react-spinners";
// import { flexbox } from "@mui/system";
import Navbar from "./Navbar";
import { sendPublication } from "../redux/actions/sendPublication";
import Mainloader from "./mainloader";
import { Document, Page, pdfjs } from 'react-pdf';


const override = {
    marginRight: "10px",
};

const ExploreTags = () => {

    const [allModal, setAllModal] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fullname, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
	const [pubRef, setPubRef] = useState('');
	const [work_place, setWorkPlace] = useState('');

	const [PDFFile, setPDFFile] = useState('')


	const [PDFVisibility, setPDFVisibility] = useState(false);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

    const sendPublicationState = useSelector(state => state.sendPublicationReducer)
    const resourcesState = useSelector(state => state.resourcesReducer)

    const dispatch = useDispatch()
    const location = useLocation()

    const tagName = location.state?.tagName || ""

    useEffect(() => {
        dispatch(getAllResources())
    }, [dispatch])

    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = (e) => {
        setAllModal(false)
        setIsModalVisible(false)
    };


    let downloadPublication = () => {

        let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

        if (!fullname) {
            toast.error("please enter your Full name")
            return
        }
        else if (!email) {
            toast.error("email cannot be empty")
            return
        }
        else if (email_reg.test(email) === false) {
            toast.error("Email Address is invalid")
            return
        }
		else if (!work_place) {
			toast.error("Work place cannot be empty")
			return
		}
		else {
			let data = {
				email,
				fullname,
				comment,
				publication_ref: pubRef,
				work_place,
			}
            dispatch(sendPublication(data)).then(() => {
                setAllModal(true)
            }).catch(() => {
                toast.error(sendPublicationState.error)
            })

        }
    }

    const filteredResources = resourcesState?.resources?.filter(filteredResources => {
        return (
            filteredResources?.publication?.tag?.toLowerCase().includes(tagName?.toLowerCase())
        )
    })

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}


    return (
        <>
            <Toaster />

			<Modal
				visible={PDFVisibility}
				onOk={()=>setPDFVisibility(false)}
				onCancel={()=>setPDFVisibility(false)}
				footer={
					<p>
						Page {pageNumber} of {numPages}
					</p>
				}
				className="createForm"
			>
				<div className="modalBody">
					<div>
						<Document file={PDFFile}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							<Page pageNumber={pageNumber} />
						</Document>
					</div>
				</div>

			</Modal>
            <div className="full-article" style={{paddingBottom:"100px"}}>
                <div className="sideBarDivArt">
                    <Sidebar />
                </div>
                <div className="navContainerArt">
                    <div className="navdiv">
                        <Navbar />
                    </div>
                    {resourcesState.isLoading ?
                        <>
                            <Mainloader />
                        </>
                        :
                        <section className="downloadSection" >
                            <div className="downloadContent" >
                                <p style={{ paddingTop: "100px" }}>Explore</p>
                                <h1>{tagName}</h1>

                                <div className="allDownloadArticle">
                                    {/* {resourcesState?.resources?.slice(0, 3).map((allResources, index) => { */}
                                    {filteredResources.map((allResources, index) => {
                                        return (
                                            <div key={index}>
                                                {allResources.type === "publication" ?
                                                    <div className="download">
                                                        <img src={"http://cms-backend.myidentitypass.com"+allResources?.publication?.image_banner} alt="download" />
                                                        <h6 className="pub-heading">{allResources?.publication?.heading}</h6>
                                                        <p className="pub-body">{allResources?.publication?.description}</p>
                                                        <div className="download-btn">
                                                            <button className="preview-btn" style={{ cursor: "pointer" }} 
                                                                onClick={() => {
                                                                    setPDFFile(allResources?.publication?.file)
                                                                    setPDFVisibility(true)
                                                                }}
                                                            >
                                                                Review PDF
                                                            </button>
                                                            <button style={{ cursor: "pointer" }} className="down-pdf" 
                                                                onClick={()=>{
																	setPubRef(allResources?.reference)
																	setIsModalVisible(true);
                                                                }}
                                                            >
                                                                Download PDF
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="articleFull">
                                                        <img src={"http://cms-backend.myidentitypass.com"+allResources?.publication?.image_banner} alt="article" />
                                                        <h3 className="articleTitle pub-heading">
                                                            {allResources?.publication?.heading}
                                                        </h3>
                                                        <p className="pub-body">{allResources?.publication?.description}</p>
                                                        <div className="firstArticleSplitFull">
                                                            <span>
                                                                <WiMoonFull className="fullmoon" />
                                                                {allResources?.publication?.read_time} mins read
                                                            </span>
                                                            <Link to={{
                                                                pathname: `/fullArticle/${allResources?.slug}`,
                                                            }}
                                                                // state={{ blogData: allResources }}

                                                                style={{ textDecoration: "none" }}>
                                                                <h5 className="read">Read More</h5>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        </section>
                    }
                    {/* <div className="footerIII"> */}
                        <Footer />
                    {/* </div> */}
                </div>
            </div>


            <div className="createformModal">
                <Modal
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    className="createForm"
                >
                    {allModal === false ?
                        <div className="modalBody">
                            <div>
                                <h5>Give us few details</h5>
                                <p>
                                    We will like to send this to your email, kindly fill the form
                                </p>
                            </div>
                            <div className="allForms">
                                <div className="form-group">
                                    <input
                                        className="mb-3"
                                        type="name"
                                        placeholder="Enter full name"
                                        controlId="formBasicEmail"
                                        value={fullname}
                                        onChange={fullname => setFullName(fullname.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="mb-3"
                                        type="email"
                                        placeholder="Email address"
                                        controlId="formBasicEmail"
                                        value={email}
                                        onChange={email => setEmail(email.target.value)}
                                    />
                                </div>
								<div className="form-group">
									<input
										className="mb-3"
										type="name"
										placeholder="Work Place"
										controlId="formBasicEmail"
										value={work_place}
										onChange={workPlace => setWorkPlace(workPlace.target.value)}
									/>
								</div>
                                <div className="form-group">
                                    <input
                                        className="mb-3"
                                        type="message"
                                        placeholder="Leave a comment (Optional)"
                                        controlId="formBasicEmail"
                                        value={comment}
                                        onChange={comment => setComment(comment.target.value)}
                                    />
                                </div>
                            </div>
                            {/* <div className="label-checkbox">
                                <label class="switch" style={{ width: 56 }}>
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                                <p>Uncheck if you don’t want to receive future updates</p>
                            </div> */}
                            <div className="modalButton">
                                <button className="bttn" onClick={downloadPublication}>
                                    {/* <ClipLoader cssOverride={override} size={50} /> */}
                                    <BounceLoader loading={sendPublicationState.isLoading} color={"white"} cssOverride={override} size={30} />
                                    {!sendPublicationState.isLoading ?
                                        "Download Reasources"
                                        :
                                        "Loading..."
                                    }
                                </button>
                            </div>
                        </div>
                        :
                        <div className="thanksContainer">
                            <div className="thanks">
                                <div className="modalImg">
                                    <img src={emailImg} alt="email" />
                                </div>
                                <div>
                                    <h5>Sent Successfully</h5>
                                    <p>
                                        We just sent you this resource to the email you provided, thank you for downloading, enjoy and share this knowledge.
                                    </p>
                                </div>
                                <div className="thanks" style={{ textAlign: "center" }}>
                                    <button className="bttn" onClick={handleCancel}>Done, Thank you</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div />
                </Modal>
            </div>
        </>
    );
};

export default ExploreTags;
