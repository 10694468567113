import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "../asset/fullArticle.css";
import { AiOutlineCalendar, AiOutlineShareAlt } from "react-icons/ai";
import { WiMoonFull } from "react-icons/wi";
import twitter from "../../src/twitter.png";
import whatsapp from "../../src/whatsapp.png";
import linkedin from "../../src/linkedin.png";
import facebook from "../../src/facebook.png";
// import Farticle from "../../src/Farticle.png";
// import Fraud from "../../src/Fraud.png";
// import article from "../../src/article.png";
import { Link, useLocation, useParams } from "react-router-dom";
import Footer from "../Component/Footer";
import { getAllResources } from "../redux/actions/resources";
import { useDispatch, useSelector } from "react-redux";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import Mainloader from "./mainloader";
import moment from "moment";
import { BounceLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { sendPublication } from "../redux/actions/sendPublication";
import { Modal } from "antd";
import emailImg from "../imageFolder/email.png";
import { RiHeart2Fill, RiHeart2Line } from "react-icons/ri";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import Cookies from "js-cookie";
import { getSingleBlog } from "../redux/actions/blog";
import { likeFavourite } from "../redux/actions/favourites";
import { Document, Page, pdfjs } from 'react-pdf';


const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

export default function FullArticle() {
	const [allModal, setAllModal] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [fullname, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [comment, setComment] = useState('');
	const [pubRef, setPubRef] = useState('');
	const [work_place, setWorkPlace] = useState('');
	const [saveArticleIsLoading, setSaveArticleIsLoading] = useState(false);
	const [articleLike, setArticleLike] = useState([]);

	const [PDFFile, setPDFFile] = useState('')


	const [PDFVisibility, setPDFVisibility] = useState(false);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);


	const blogState = useSelector(state => state.blogReducer)
	const resourcesState = useSelector(state => state.resourcesReducer)
	const sendPublicationState = useSelector(state => state.sendPublicationReducer)
	const favouriteState = useSelector(state => state.favouritesReducer)

	const dispatch = useDispatch()

	// const location = useLocation()

	// const blogState?.singleBlog?.[0]? = location.state?.blogData

	const { ref } = useParams();

	useEffect(() => {
		setArticleLike(Cookies.get('likedArticles') ? JSON.parse(Cookies.get('likedArticles')) : [])

		dispatch(getAllResources())

		dispatch(getSingleBlog(ref))

	}, [dispatch, ref])


	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = (e) => {
		setAllModal(false)
		setIsModalVisible(false)
	};


	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}



	let downloadPublication = () => {

		let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

		if (!fullname) {
			toast.error("please enter your Full name")
			return
		}
		else if (!email) {
			toast.error("email cannot be empty")
			return
		}
		else if (email_reg.test(email) === false) {
			toast.error("Email Address is invalid")
			return
		}
		else if (!work_place) {
			toast.error("Work place cannot be empty")
			return
		}
		else {
			let data = {
				email,
				fullname,
				comment,
				publication_ref: pubRef,
				work_place,
			}
			dispatch(sendPublication(data)).then(() => {
				setAllModal(true)
			}).catch(() => {
				toast.error(sendPublicationState.error)
			})

		}
	}


	let bookmarkArticle = () => {
		let data = {
			reference: blogState?.singleBlog?.[0]?.id
		}

		let getSavedArticles = Cookies.get('savedArticles') ? JSON.parse(Cookies.get('savedArticles')) : []
		// let setSavedArticles = []

		if (!getSavedArticles) {
			setSaveArticleIsLoading(true)
			getSavedArticles.push(data.reference)

			Cookies.set('savedArticles', JSON.stringify(getSavedArticles))
			setSaveArticleIsLoading(false)
			toast.success("You have successfully saved this article")
			return
		}
		else if (getSavedArticles?.includes(data.reference)) {
			toast.error("This article has already been saved")
			return
		}
		else {
			setSaveArticleIsLoading(true)
			getSavedArticles.push(data.reference)

			Cookies.set('savedArticles', JSON.stringify(getSavedArticles))
			setSaveArticleIsLoading(false)
			toast.success("You have successfully saved this article")
		}


		// dispatch(saveFavourite(data)).then(() => {
		// }).catch(() => {
		// 	toast.error(sendPublicationState.error)
		// })

	}

	let likeArticle = () => {

		let getLikedArticles = Cookies.get('likedArticles') ? JSON.parse(Cookies.get('likedArticles')) : []

		if (!getLikedArticles) {

			let data = {
				article_id: blogState?.singleBlog?.[0]?.id,
				action: 1
			}

			dispatch(likeFavourite(data)).then(() => {
				getLikedArticles.push(data.article_id)
				Cookies.set('likedArticles', JSON.stringify(getLikedArticles))
				toast.success("You liked this article")
				dispatch(getSingleBlog(ref))
				setArticleLike(Cookies.get('likedArticles') ? JSON.parse(Cookies.get('likedArticles')) : [])
			}).catch(() => {
				toast.error(favouriteState.error)
			})

			return
		}
		else if (getLikedArticles?.includes(blogState?.singleBlog?.[0]?.id)) {

			let LikedArticleIndex = getLikedArticles.indexOf(blogState?.singleBlog?.[0]?.id)

			let data = {
				article_id: blogState?.singleBlog?.[0]?.id,
				action: 0
			}

			dispatch(likeFavourite(data)).then(() => {
				var splicedArticle = getLikedArticles.splice(LikedArticleIndex, 0)
				Cookies.set('likedArticles', JSON.stringify(splicedArticle))
				toast.success("You unliked this article")
				dispatch(getSingleBlog(ref))
				setArticleLike(Cookies.get('likedArticles') ? JSON.parse(Cookies.get('likedArticles')) : [])
			}).catch(() => {
				toast.error(favouriteState.error)
			})
			return
		}
		else {

			let data = {
				article_id: blogState?.singleBlog?.[0]?.id,
				action: 1
			}

			dispatch(likeFavourite(data)).then(() => {
				getLikedArticles.push(data.article_id)
				Cookies.set('likedArticles', JSON.stringify(getLikedArticles))
				toast.success("You liked this article")
				dispatch(getSingleBlog(ref))
				setArticleLike(Cookies.get('likedArticles') ? JSON.parse(Cookies.get('likedArticles')) : [])
			}).catch(() => {
				toast.error(favouriteState.error)
			})

		}

	}

	let removeTags = /<(.*?)>/g

	return (
		<>
			<Toaster />


			<Modal
				visible={PDFVisibility}
				onOk={() => setPDFVisibility(false)}
				onCancel={() => setPDFVisibility(false)}
				footer={
					<p>
						Page {pageNumber} of {numPages}
					</p>
				}
				className="createForm"
			>
				<div className="modalBody">
					<div>
						<Document file={PDFFile}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							<Page pageNumber={pageNumber} />
						</Document>
					</div>
				</div>

			</Modal>

			<div className="side-action-area" style={{ zIndex: 3 }}>
				<div>
					{favouriteState?.isLoading ?
						<BounceLoader loading={favouriteState?.isLoading} color={"black"} cssOverride={override} size={30} />
						:
						<button className="side-icon-bg" onClick={likeArticle}>
							{articleLike?.includes(blogState?.singleBlog?.[0]?.id) ?
								<RiHeart2Fill className="side-icon" style={{ color: "red" }} />
								:
								<RiHeart2Line className="side-icon" />
							}
						</button>
					}
					<p>{blogState?.singleBlog?.[0]?.like_publication?.total || "0"}</p>
				</div>
				<div>
					<button className="side-icon-bg" onClick={bookmarkArticle}>
						{saveArticleIsLoading ?
							<BounceLoader loading={saveArticleIsLoading} color={"black"} cssOverride={override} size={30} />
							:
							<MdOutlineBookmarkAdd className="side-icon" />
						}
					</button>
				</div>
			</div>

			<div className="full-article">
				<div className="sideBarDivArt">
					<Sidebar />
				</div>
				<div className="navContainerArt">
					<div className="navdiv">
						<Navbar />
					</div>
					{blogState.isLoading ?
						<>
							<Mainloader />
						</>
						:
						<>
							<section className="fullArticleSection">
								<div className="articleCard" style={{ zIndex: 2 }}>
									<h1>
										{blogState?.singleBlog?.[0]?.publication?.heading}
									</h1>
									<hr className="articleUnderline" />
									<div className="articleIcons" >
										<div className="fullArticlecalender">
											<AiOutlineCalendar className="calender" style={{ marginRight: "5px", marginTop: "3px" }} />
											{/* <p>Oct, 20 2021</p> */}
											<p style={{ marginTop: "2px" }}>{moment().calendar(blogState?.singleBlog?.[0]?.create_at)}</p>
										</div>
										<span className="moonIcon">
											<WiMoonFull className="moon" />
											{blogState?.singleBlog?.[0]?.publication?.read_time} mins read
										</span>
									</div>
									<div className="socialHandle">
										<ul>
											<li className="socialList facebook">
												<FacebookShareButton
													url={`http://cms-front-dev.myidentitypay.com/fullArticle/${blogState?.singleBlog?.[0]?.slug}`}
													quote={blogState?.singleBlog?.[0]?.publication?.heading}
													description={blogState?.singleBlog?.[0]?.publication?.heading}
												>
													<img src={facebook} alt="insta" />
												</FacebookShareButton>
											</li>
											<li className="socialList">
												<WhatsappShareButton
													url={`http://cms-front-dev.myidentitypay.com/fullArticle/${blogState?.singleBlog?.[0]?.slug}`}
													separator={" ,"}
													title={blogState?.singleBlog?.[0]?.publication?.heading}
												>
													<img src={whatsapp} alt="insta" />
												</WhatsappShareButton>
											</li>
											<li className="socialList">
												<TwitterShareButton
													url={`http://cms-front-dev.myidentitypay.com/fullArticle/${blogState?.singleBlog?.[0]?.slug}`}
													title={blogState?.singleBlog?.[0]?.publication?.heading}
												>
													<img src={twitter} alt="twitter" />
												</TwitterShareButton>
											</li>
											<li className="socialList linkedin">
												<LinkedinShareButton
													url={`http://cms-front-dev.myidentitypay.com/fullArticle/${blogState?.singleBlog?.[0]?.slug}`}
													title={blogState?.singleBlog?.[0]?.publication?.heading}
												>
													<img src={linkedin} alt="linkedin" />
												</LinkedinShareButton>

											</li>
											<li>
												<button className="socialBtn" disable="true">
													<AiOutlineShareAlt className="share" />
													Share
												</button>
											</li>
										</ul>
									</div>
								</div>
								<div className="imageDiv" style={{ zIndex: 2 }}>
									<img src={blogState?.singleBlog?.[0]?.publication?.image_banner} alt="Farticle" />
								</div>
							</section>
							<div className="blogTexts" style={{ zIndex: 1 }}>
								<div className="large-text">
									<span dangerouslySetInnerHTML={{ __html: blogState?.singleBlog?.[0]?.publication?.body }} />
									{/* <p>
									{blogState?.singleBlog?.[0]?.publication?.body}
								</p> */}
								</div>
								{/* <div className="emoji">
									<h3>How was this blog post ?</h3>
									<ul>
										<li>😊</li>
										<li>😁</li>
										<li>🙂</li>
										<li>🙁</li>
										<li>😠</li>
										<li>😡</li>
									</ul>
								</div> */}
							</div>

							<section className="downloadSection">
								<div className="downloadContent">
									<h4>Explore other Resources</h4>

									{resourcesState.isLoading ?
										<Mainloader />
										:
										<div className="allDownloadArticle">
											{resourcesState?.resources?.slice(0, 3).map((allResources, index) => {
												return (
													<div key={index}>
														{allResources.type === "publication" ?
															<div className="download">
																<img src={"http://cms-backend.myidentitypass.com" + allResources?.publication?.image_banner} alt="download" />
																<h6 className="pub-heading">{allResources?.publication?.heading}</h6>
																<p className="pub-body">{allResources?.publication?.body.replace(removeTags, "")}</p>
																<div className="download-btn">
																	<button className="preview-btn" style={{ cursor: "pointer" }}
																		onClick={() => {
																			setPDFFile(allResources?.publication?.file)
																			setPDFVisibility(true)
																		}}
																	>
																		Review PDF
																	</button>
																	<button className="down-pdf"
																		onClick={() => {
																			setPubRef(allResources?.reference)
																			setIsModalVisible(true);
																		}}
																	>
																		Download PDF
																	</button>
																</div>
															</div>
															:
															<div className="articleFull">
																<img src={"http://cms-backend.myidentitypass.com" + allResources?.publication?.image_banner} alt="article" />
																<h3 className="articleTitle pub-heading">
																	{allResources?.publication?.heading}
																</h3>
																<p className="pub-body">{allResources?.publication?.body.replace(removeTags, "")}</p>
																<div className="firstArticleSplitFull">
																	<span>
																		<WiMoonFull className="fullmoon" />
																		{allResources?.publication?.read_time} mins read
																	</span>
																	<Link to={{
																		pathname: `/fullArticle/${allResources?.slug}`,
																	}}
																		// state={{ blogData: allResources }}

																		style={{ textDecoration: "none" }}>
																		<h5 className="read">Read More</h5>
																	</Link>
																</div>
															</div>
														}
													</div>
												)
											})

											}
										</div>
									}
								</div>
							</section>
						</>
					}

					<div className="createformModal">
						<Modal
							visible={isModalVisible}
							onOk={handleOk}
							onCancel={handleCancel}
							footer={null}
							className="createForm"
						>
							{allModal === false ?
								<div className="modalBody">
									<div>
										<h5>Give us few details</h5>
										<p>
											We will like to send this to your email, kindly fill the form
										</p>
									</div>
									<div className="allForms">
										<div className="form-group">
											<input
												className="mb-3"
												type="name"
												placeholder="Enter full name"
												controlId="formBasicEmail"
												value={fullname}
												onChange={fullname => setFullName(fullname.target.value)}
											/>
										</div>
										<div className="form-group">
											<input
												className="mb-3"
												type="email"
												placeholder="Email address"
												controlId="formBasicEmail"
												value={email}
												onChange={email => setEmail(email.target.value)}
											/>
										</div>
										<div className="form-group">
											<input
												className="mb-3"
												type="name"
												placeholder="Work Place"
												controlId="formBasicEmail"
												value={work_place}
												onChange={workPlace => setWorkPlace(workPlace.target.value)}
											/>
										</div>
										<div className="form-group">
											<input
												className="mb-3"
												type="message"
												placeholder="Leave a comment (Optional)"
												controlId="formBasicEmail"
												value={comment}
												onChange={comment => setComment(comment.target.value)}
											/>
										</div>
									</div>
									{/* <div className="label-checkbox">
										<label class="switch" style={{ width: 56 }}>
											<input type="checkbox" />
											<span class="slider round"></span>
										</label>
										<p>Uncheck if you don’t want to receive future updates</p>
									</div> */}
									<div className="modalButton">
										<button className="bttn" onClick={downloadPublication}>
											{/* <ClipLoader cssOverride={override} size={50} /> */}
											<BounceLoader loading={sendPublicationState.isLoading} color={"white"} cssOverride={override} size={30} />
											{!sendPublicationState.isLoading ?
												"Download Reasources"
												:
												"Loading..."
											}
										</button>
									</div>
								</div>
								:
								<div className="thanksContainer">
									<div className="thanks">
										<div className="modalImg">
											<img src={emailImg} alt="email" />
										</div>
										<div>
											<h5>Sent Successfully</h5>
											<p>
												We just sent you this resource to the email you provided, thank you for downloading, enjoy and share this knowledge.
											</p>
										</div>
										<div className="thanks" style={{ textAlign: "center" }}>
											<button className="bttn" onClick={handleCancel}>Done, Thank you</button>
										</div>
									</div>
								</div>
							}
							<div />
						</Modal>
					</div>
					{/* <div className="footerIII"> */}
					<Footer />
					{/* </div> */}
				</div>
			</div>
		</>
	);
};
