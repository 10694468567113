import { actionTypes } from "../../constants/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    favourites: [],
}

export const favouritesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_ALL_FAVOURITES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_ALL_FAVOURITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                favourites: payload.favourites
            };
        case actionTypes.SAVE_FAVOURITE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.LIKE_FAVOURITE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.GET_ALL_FAVOURITES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        default:
            return state;
    }
}

