import { actionTypes } from "../../constants/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    blog: [],
    singleBlog:{}
}

export const blogReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_ALL_BLOG_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_ALL_BLOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                blog:payload
            };
        case actionTypes.GET_SINGLE_BLOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                singleBlog:payload
            };
        case actionTypes.GET_ALL_BLOG_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        default:
            return state;
    }
}

