import React, { Component } from 'react'
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "../asset/ourBlog.css";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { AiOutlineCalendar } from "react-icons/ai";
import { WiMoonFull } from "react-icons/wi";
// import article from "../../src/article.png";
// import identityPass from "../../src/identityPass.png";
import Footer from "../Component/Footer";
import { getAllBlog } from '../redux/actions/blog';
import { connect } from 'react-redux';
import Mainloader from './mainloader';
import moment from 'moment';


class OurBlog extends Component {

	componentDidMount() {
		this.props.getAllBlog().then(() => { }).catch(() => { })
	}

	render() {
		let removeTags = /<(.*?)>/g
		return (
			<div className="containerOurBlog">
				<div className="sideBarDivBlog">
					<Sidebar />
				</div>
				<div className="navContainerBlog">
					<div className="navdiv">
						<Navbar />
					</div>
					{this.props.blogState.isLoading ?
						<>
							<Mainloader />
						</>
						:
						<div className="blogContainer" style={{paddingBottom:"100px"}}>
							<section className="ourBlogSection">
								<div className="blogTitle">
									<h2 className="blogDescs">
										<span className="desc">Explore</span>
										<br />
										Our Blog
									</h2>
								</div>
								<div className="blogImg">
									<div className="position">
										{/* <img src={identityPass} alt="identityPass" className="positionImg"/> */}
										<div className="cards">
											<Card>
												{this.props?.blogState?.blog?.slice(0, 1).map((blog, index) => {
													return (
														<Card.Body>
															<Card.Title>
																<p className='pub-heading'>
																	{blog?.publication?.heading}
																</p>
																{/* Identitypass raises <br /> $2.8m Seed funding led by{" "}
															<br /> Mac Waring Ventures. */}
															</Card.Title>
															<Card.Text>
																<p className='pub-body'>
																	{blog?.publication.body?.replace(removeTags, "")}
																</p>
																{/* IdentityPass just raised her seed funding following the
															massive launch and announcement of their new system
															update. */}
															</Card.Text>
															<hr className="raise" />
															<div className="allIcon">
																<div className="calender" style={{ paddingTop: 10 }}>
																	<AiOutlineCalendar className="calenderIcon" style={{ marginTop: 2 }} />
																	{/* <p>Oct, 20 2021</p> */}
																	<p>{moment().calendar(blog?.create_at)}</p>
																</div>
																<span>
																	<WiMoonFull className="moon" />
																	{blog?.publication?.read_time} mins read
																</span>
															</div>
															<Link to={{
																pathname: `/fullArticle/${blog.slug}`,
															}}
																state={{ blogData: blog }}
															>
																<h6>Read full article</h6>
															</Link>
														</Card.Body>
													)
												})}
											</Card>
										</div>
									</div>
								</div>
							</section>
							<section className="articleSection">
								{this.props?.blogState?.blog?.map((blog, index) => {
									return (
										<div className="article" key={index + 1}>
											<img src={blog?.publication?.image_banner} alt="article" />
											<h3 className="articleTitle .pub-heading">
												{blog?.publication?.heading}
											</h3>
											<p className='pub-body'>
												{blog?.publication?.body.replace(removeTags, "")}
											</p>
											<div className="firstArticleSplit">
												<span>
													<WiMoonFull className="fullmoon" />
													{blog?.publication?.read_time} mins read
												</span>
												<Link to={{
													pathname: `/fullArticle/${blog?.slug}`,
												}}
													// state={{ blogData: blog }}
													style={{ textDecoration: "none" }}
												>
													<h4 className="read">Read More</h4>
												</Link>
											</div>
										</div>
									)
								})}
							</section>
						</div>
					}
					{/* <div className="footerII"> */}
						<Footer />
					{/* </div> */}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	blogState: state.blogReducer,
});

const mapDispatchToProps = dispatch => ({
	getAllBlog: () => dispatch(getAllBlog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OurBlog);

