import React from 'react'
import "../asset/sidebar.css";
import { MdOutlineBookmarkAdd} from 'react-icons/md';
import { RiHomeLine } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter }  from "react-icons/fa";
import { FaFacebookF }  from "react-icons/fa";
import  { FaInstagram } from "react-icons/fa";
import Logo3 from "../../src/Logo3.png";
import { Link } from "react-router-dom";
import { useState } from 'react';
const Sidebar = () => {
    const [homeColor, setHomeColor] = useState({
              color:"#303AF2"
    });

    const handleClick = () => {
        setHomeColor({
            color:"black"
        });
    }
  return (
        <div className="sidebarContainer" id="sidebarCon">
            <div className="sidebarLogo">
                <Link to="/"  style={{textDecoration:"none", color:"transparent"}}>
                    <img src={Logo3} alt="logo"/>
                </Link>
            </div>
            <div className="sidebarIcons">
                <ul className="icon-ui">
                    <li><Link to='/' style={{textDecoration:"none", color:"black"}}><RiHomeLine className="homeIcon"   onClick={handleClick}/><span className="iconText">Home</span></Link></li>
                    <li><Link to='/saveBlog' style={{textDecoration:"none", color:"black"}}><MdOutlineBookmarkAdd  className="iconlist" style={{ color: "black" }}/>Saved</Link></li>
                </ul>
            </div>
            <footer className="socialMedia">
                <ul>
                    <li><FaLinkedinIn/></li>
                    <li><FaTwitter/></li>
                    <li><FaInstagram/></li>
                    <li><FaFacebookF/></li>
                </ul>
                <p className="followUs">Follow Us <span className="hyphen">-</span></p>
            </footer>
            </div>
  )
}

export default Sidebar;