import { actionTypes } from "../../constants/actionTypes"
import axios from "axios"

export const getAllPublicationRequest = () => {
    return {
        type: actionTypes.GET_ALL_PUBLICATION_REQUEST,
    }
}

export const getAllPublicationSuccess = (publication) => {
    return {
        type: actionTypes.GET_ALL_PUBLICATION_SUCCESS,
        payload: publication
    }
}

export const getAllPublicationFailure = (error) => {
    return {
        type: actionTypes.GET_ALL_PUBLICATION_FAILURE,
        payload: error
    }
}


//get all Resources
export const getAllPublication = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllPublicationRequest());

        var config = {
            method: 'get',
            url: 'https://cms-backend.myidentitypass.com/resource/publication/collections',
            headers: {}
        };


        axios(config).then(function (response) {
            dispatch(getAllPublicationSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllPublicationFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}
