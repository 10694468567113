import React, { useEffect } from "react";
import "../asset/home.css";
import haz from "../haz.png";
import laptop from "../laptop.png";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { AppBar, Typography } from "@mui/material";
import { MdChevronRight } from "react-icons/md";
import { tags } from "./data";
import AllTags from "./AllTags";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import { ArrowForwardIos } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAllTags } from "../redux/actions/tags";
import Mainloader from "./mainloader";
import mobileLogo from "../Logo3.png"
import { height } from "@mui/system";
// import { ArrowForwardIos  } from "@material-ui/icons";

const Home = (props) => {

  const tagsState = useSelector(state => state.tagsReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllTags())
  }, [dispatch])


  const { className, onClick } = props;
  //   return (
  //     <div className={className} onClick={onClick}>
  //       <ArrowBackIos style={{ color: "blue", fontSize: "30px" }} />
  //     </div>
  //   );
  // };
  const NextBtn = (props) => {
    const { onClick } = props;
    return (
      <ArrowForwardIos
        style={{
          color: "black",
          fontSize: "15px",
          position: "absolute",
          top: "0px",
          right: "15px",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextBtn />,
  };

  const sliderRight = () => {
    let slider = document.getElementsByClassName("hidden");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  return (
    <div style={{paddingBottom:"100px"}}>
      <div className="containerNav">
        <div className="sideBarDiv">
          <Sidebar />
        </div>
        <div className="navContainer">
          {/* <div className="navCon">
            <Navbar />
          </div> */}

          {tagsState.isLoading ?
            <>
              <Mainloader />
            </>
            :
            <div className="homesection">
              <div className="mobile-logo" style={{textAlign:"center"}}>
                <img src={mobileLogo} alt="" style={{width:"40px", height:"100% !important"}}/>
              </div>
              <section className="tags">
                <div className="title">
                  <h1 className="titleMaking">
                    <span className="intro" id="intro">
                      At IdentityPass, We are
                    </span>
                    <br />
                    Making Knowledge Accessible...
                  </h1>
                </div>
                <div className="topics">
                  <h5>Topics we write on</h5>
                  <ul>
                    {tagsState?.tags?.map(value => {
                      return (
                        <li>
                          <Link to={{
                            pathname: `/ExploreTags/${value.name}`,
                          }}
                            state={{ tagName: value.name }}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            {value.name}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="newtopics" id="newtopics">
                  <h4>Topics we write on</h4>
                  <ul>
                    <Slider {...settings}>
                      {tagsState?.tags?.map(value => (
                        <li>
                          <Link to={{
                            pathname: `/ExploreTags/${value.name}`,
                          }}
                            state={{ tagName: value.name }}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            {value.name}
                          </Link>
                        </li>
                      ))}
                    </Slider>
                  </ul>
                  {/* <div className="rightAn">
                     <MdChevronRight size={30} onClick={sliderRight} />
                </div> */}
                </div>
              </section>
              <section className="blogs">
                <div className="typeOfBlogs">
                  <div className="blogTitle">
                    <h1>Explore Our Knowledge Base</h1>
                    <hr className="underline" />
                  </div>

                  <div className="blogContent">
                    <div className="setBlog">
                      <div className="box">
                        <img src={laptop} alt="post" />
                        <div className="blogItems">
                          <h5>Our Blog Posts</h5>
                          <p>
                            Keep up with the latest Identitypass news, identity 
                            verification insights, and product updates.
                          </p>
                          <Link to="/ourBlog">
                            <button className="blog-btn">Explore</button>
                          </Link>
                        </div>
                      </div>
                      <div className="box">
                        <img src={haz} alt="post" />
                        <div className="blogItems">
                          <h5>Our Researches</h5>
                          <p>
                            Access expert resources on Identity verification, Compliance 
                            and Security in Africa
                          </p>
                          <Link to="/publication">
                            <button className="blog-btn">Explore</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          }
          <div className="footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
