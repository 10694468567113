import React from "react";
import "../asset/navbar.css";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import mobileLogo from "../Logo3.png"
import {
  AppBar,
  // Avatar,
  // Badge,
  Box,
  // InputBase,
  // Menu,
  // MenuItem,
  styled,
  // TextField,
  Toolbar,
  // Typography,
} from "@mui/material";
import { RiNotification2Line } from "react-icons/ri";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
// import OurBlog from "./OurBlog";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Icons = styled(Box)(({ theme }) => ({
  alignItems: "center",
  gap: "20px",
  display: "flex",
}));

// const UserBox = styled(Box)(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   gap: "10px",
//   [theme.breakpoints.up("sm")]: {
//     display: "visible",
//   },
// }));

// const label = { zIndex: 100 };

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [backButton, setBackButton] = useState(false);

  const navigate = useNavigate();

  let location = useLocation()
  const {pathname} = location
  const pageLocation = pathname.split("/")

  const showButton = () => {
    setBackButton(true);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "#fff",
        borderBottom: "1px solid #C1C7D0",
        boxShadow: "none",
      }}
    >
      <StyledToolbar>
        <button className="typography"
          onClick={
            pageLocation[1] === "publication" ? () => navigate("/")
            :
            pageLocation[1] === "ourBlog" ? () => navigate("/")
            :
            pageLocation[1].includes("ExploreTags") ? () => navigate("/")
            :
            () => navigate(-1)
          }
        >
          <HiOutlineArrowNarrowLeft
            style={{
              width: "40px",
              height: "25px",
              fontWeight: "500",
              marginLeft: "-13px",
            }}
          />
          Back
        </button>
        <div className="mobile-logo">
          <img src={mobileLogo} alt=""/>
        </div>
        {/* <Icons display="flex" marginLeft="auto">
          <div className="notification">
            <RiNotification2Line
              style={{ width: 22, height: 22 }}
              className="notifyIcon"
            />
            <span className="newNotification">4</span>
          </div>
          <span className="border"></span>
        </Icons> */}
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;


