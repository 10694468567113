import React, { Component } from 'react'
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import publication from "../asset/publication.css";
import Fraud from "../../src/Fraud.png";
import { Link } from "react-router-dom";
import "../asset/publication.css";
import AllFraud from "./AllFraud";
import { cards } from "../Component/data";
import Footer from "./Footer";
import { connect } from 'react-redux';
import { getAllPublication } from '../redux/actions/publication';
import Mainloader from './mainloader';

import { Document, Page } from 'react-pdf';

class OurPublication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1
    };
  }


  componentDidMount() {
    this.props.getAllPublication().then(() => { }).catch(() => { })
  }

  render() {
    return (
      <>
      <div className="publicationContainer" style={{paddingBottom:"100px"}}>
        <div className="sideBarPublication">
          <Sidebar />
        </div>
        <div className="navContainer">
          <div className="navdivCon">
            <Navbar />
          </div>
          {this?.props?.publicationState?.isLoading ?
            <>
              <Mainloader />
            </>
            :
            <div className="ourPublicationContainer">
              <section className="ourPublicationContent">
                <div className="publicationTitle">
                  <h2 className="blogDescs">
                    <span className="publicationDesc">Explore</span>
                    <br />
                    Our Researches
                  </h2>
                </div>
                <div className="allFrauds">
                  {this.props?.publicationState?.publication.length > 0 ?
                    this?.props?.publicationState?.publication?.map((item)=> (
                      <AllFraud item={item} key={item.id} />
                    ))
                    :
                    <h1>No researches here yet</h1>
                  }
                </div>
              </section>
            </div>
          }
        </div>
        <Footer />
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  publicationState: state.publicationReducer,

});

const mapDispatchToProps = (dispatch) => ({
  getAllPublication: () => dispatch(getAllPublication()),

});

export default connect(mapStateToProps, mapDispatchToProps)(OurPublication);

