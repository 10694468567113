import React, { useEffect, useState } from "react";
import "../asset/saveBlog.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Link } from 'react-router-dom';
import illustration from "../../src/illustration.png"
import { useDispatch, useSelector } from "react-redux";
import { getAllFavourites } from "../redux/actions/favourites";
import Cookies from "js-cookie";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import { RiMoreFill } from "react-icons/ri";
import fraudImg from "../../src/Fraud.png"
import { ReplayCircleFilled } from "@mui/icons-material";
import Mainloader from "./mainloader";
import moment from "moment";
import Footer from "./Footer";

const SaveBlog = () => {
	const [articleLike, setArticleLike] = useState([]);

	const favouritesState = useSelector(state => state.favouritesReducer)
	const dispatch = useDispatch()


	useEffect(() => {
		const data = {
			savedArticle: Cookies.get('savedArticles')?.replace(/[\[\]']+/g, '')
		}

		setArticleLike(Cookies.get('likedArticles') ? JSON.parse(Cookies.get('likedArticles')) : [])

		dispatch(getAllFavourites(data)).then(() => { })
	}, [dispatch])

	let removeTags = /<(.*?)>/g

	return (
		<div className="saveBlogContainer" style={{paddingBottom:"100px"}}>
			<div className="sideBarDiv">
				<Sidebar />
			</div>
			<div className="navContainer">
				<Navbar />
				{favouritesState.isLoading ?
					<>
						<Mainloader />
					</>
					:
					<div className="saveBlogContent">
						<section className="saveBlogSection">
							<div className="saveBlogTitle">
								<h2 className="saveDesc">
									<span className="saveBlogdesc">Here they are</span>
									<br />
									Resources you have saved
								</h2>
							</div>
						</section>

						{favouritesState?.favourites?.length > 0 ?
							<>
								{favouritesState?.favourites?.map((savedArticles, index) => (
									<div className="sample-saved-article" key={index+1}>
										<div className="saved-article-image">
											<img src={"http://cms-backend.myidentitypass.com"+savedArticles?.publication?.image_banner} className="" alt="" />
										</div>
										<div className="saved-article-body">
											<h2>{savedArticles?.publication?.heading}</h2>
											<p className="pub-body">{savedArticles?.publication?.body?.replace(removeTags, "")}</p>
											<div className="saved-article-actions" >
												<small>
													{articleLike?.includes(savedArticles?.id) ?
														<AiFillHeart className="action-icons" style={{color:"red"}} />
														:
														<AiOutlineHeart className="action-icons" />
													}
													<h5>{savedArticles?.like_publication?.total || "0"} Likes</h5>
												</small>
												{/* <small>
													<HiDownload className="action-icons" />
													<h5>20 downloads</h5>
												</small>
												<small>
													<AiOutlineHeart className="action-icons" />
													<h5>50 Likes</h5>
												</small>
												<small>
													<AiOutlineHeart className="action-icons" />
													<h5>50 Likes</h5>
												</small> */}
											</div>
										</div>
										<div className="saved-article-read">
											<h3>Published {savedArticles?.publication?.published_dated}</h3>
											<div>
												<Link to={{
													pathname: `/fullArticle/${savedArticles?.slug}`,
												}}
												>
													Read Article
												</Link>
												{/* <RiMoreFill style={{fontSize:20}} /> */}
											</div>
										</div>

									</div>
								))}
							</>
							:

							<section className="savedItemSection">
								<div className="savedItem">
									<img src={illustration} alt="illustration" className="illustration" />
									<h5>Nothing here yet! you do not have any saved <br /> resources
									</h5>
									<p>Start reading and saving right away</p>
									<Link to="/" style={{ textDecoration: "none" }}><button>Explore Resources</button></Link>
								</div>
							</section>
						}

					</div>
				}
			</div>
            <Footer />
		</div>
	);
};

export default SaveBlog;
